.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

body {
    background-color: #23222b;
}

.modal {
    background-color: #132031;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.9);
    transition: transform 0.3s ease;
    position: relative;
}

.modal h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #9fcb52;
    text-align: start;
}

img {
    border-radius: 8px;
    margin-bottom: 15px;
    width: 100%;
    height: 350px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

strong {
    color: #9fcb52;
}

button {
    background-color: #007BFF;
    color: white;
    border: none;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

button:focus {
    outline: none;
}

.closeButton {
    position: absolute;
    left: 85%;
    bottom: 94%;
    width: 30px;
    height: 30px;
    background-color: transparent;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #e74c3c;
    background-color: transparent;
}


@media (max-width: 768px) {
    .modal {
        max-width: 80%;
        max-height: 90%;
        width: 100%;
    }

    button {
        background-color: #007BFF;
        width: 100%;
        margin-top: 0px;
        border-radius: 5px;
        transition: background-color 0.3s;
    }
}