.admin-panel {
  padding: 10px;
  display: flex;
  min-height: 92vh;
  flex-direction: row;
  transition: all 0.3s ease;
  background-color: white;
  font-family: 'Agenor Neue', sans-serif;
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
}

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  transition: all 0.3s ease;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: start;
}

.button-container input {
  width: 400px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: 10px 0px;
}

.order-buttons {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: space-evenly;
}

.order-td{
  width: 100px;
}

.order-btn {
  background-color: transparent;
  color: black;
}

.order-btn:hover {
  background-color: #28a745;
  color: white;
}

.button-container h3 {
  cursor: pointer;
  background-color: #28a745;
  width: 10%;
  color: white;
  margin-right: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
}

.button-container h3:hover {
  background-color: #28aaa5;
  transform: scale(1.05);
}

.form-group {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.input-field {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #80bdff;
  outline: none;
}

.btn {
  background-color: #28a745;
  color: white;
  width: 15%;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #28aaa5;
  transform: scale(1.05);
}

.btn-delete:hover {
  background-color: #c82333;
}

.btn-edit {
  background-color: #007bff;
}

.btn-delete {
  background-color: #dc3545;
}

.btn-edit:hover {
  background-color: #0056b3;
}

.admin-table {
  overflow-x: scroll;
  overflow-y: scroll;
  min-width: 100%;
  width: 100%;
  border-collapse: collapse;
}

.admin-table th,
.admin-table td {
  padding: 10px;
  min-width: 120px;
  text-align: start;
  max-height: 100px;
}

.buttons-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons-column button {
  margin: 0px 10px;
}


.admin-table th {
  font-weight: bold;
}

.admin-table td {
  vertical-align: middle;
  font-size: 18px;
  border-bottom: 1px solid #aaa;
}

.image-warn {
  font-size: 14px;
  color: grey;
  margin-left: 3px;
}


.admin-table td img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 0px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .admin-panel {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 0px;
    display: block;
  }

  .sidebar+.main-content {
    margin-left: 75px;
  }

  .sidebar {
    width: 100%;
    height: 130%;
  }

  .main-content {
    padding: 10px;
    margin: 0;
    display: block;
  }

  .buttons-column {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .button-container h3 {
    width: 120px;
  }

  .admin-table {
    font-size: 14px;
    display: block;
  }

  .admin-table img {
    max-width: 100px;
  }

  .form-group {
    padding: 15px;
  }

  .admin-panel td {
    font-size: 15px;
  }

}