.productTitle {
    text-align: center;
    font-size: 1.8rem;
    margin: 5px 0;
    color: #9fcb52;
}

.productList {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.productItem {
    display: flex;
    flex-direction: row;
    justify-content: start;
    background-color: #0d1724;
    border-radius: 15px;
    padding: 15px;
    width: 400px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.productItem:hover {
    transform: scale(1.05);
    background-color: #0d1724;
}

.ProductCol {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    align-items: start;
    margin-left: 20px;
    background-color: transparent;
}

.productName {
    font-size: 1.4rem;
    margin-bottom: 10px;
    text-align: start;
    color: #9fcb52;
    background-color: transparent;
}

.productPrice {
    font-size: 1.4rem;
    color: #f3f3f3;
    background-color: transparent;
}

.productImage {
    border-radius: 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 0px;
    margin-bottom: 0px;
}

.noProducts {
    text-align: center;
    color: #999;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .productList {
        padding: 0;
        margin: 0;
        gap: 5px;
    }

    .productItem {
        width: 85%;
        padding: 10px;
    }

    .productItem:hover {
        transform: scale(1.05);
        background-color: #0d1724;
    }

    .ProductCol {
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: start;
        align-items: start;
        margin-left: 20px;
    }
}