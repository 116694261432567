.sidebar {
  width: 250px;
  background-color: #333;
  color: black;
  position: fixed;
  padding: 20px;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
}

.sidebar a.active {
  color: #0D6EFD;
}

.sidebar a.active svg {
  fill: #0D6EFD;
}

.sidebar a:hover {
  text-decoration: none;
  color: #0D6EFD;
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar .toggle-btn {
  background: none;
  align-items: center;
  padding: 16px;
  color: white;
  font-size: 24px;
  width: 30px;
  cursor: pointer;
  margin: 20px 0;
}

.logout-btn {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  text-align: start;
  width: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.sidebar li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-align: start;
  padding: 6px 0px;
}

.sidebar li svg {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar.collapsed li {
  height: 30px;
  width: 30px;
  justify-content: center;
  padding: 10px;
}

.sidebar.collapsed li svg {
  font-size: 20px;
}

.sidebar.collapsed li span {
  display: none;
}

.sidebar.collapsed li svg {
  margin-right: 0;
}

.logout-btn {

}

.logout-btn:hover {
  background-color: white;
  color: red;
}

.logout-btn svg {
  margin-right: 10px;
  font-size: 18px
}

@media (max-width: 768px) {
  .sidebar {
    width: 250px;
    height: 125%;
    position: absolute;
    transition: transform 0.3s ease;
  }

  .sidebar.collapsed {
    transform: translateX(0);
  }

  .sidebar ul {
    margin-top: 30px;
    padding-top: 30px;
  }

  .sidebar .toggle-btn {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .sidebar li {
    justify-content: start;
  }

  .logout-btn {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .main-content {
    margin-left: 270px;
  }

  .sidebar.collapsed+.main-content {
    margin-left: 100px;
  }
}