.access-reports-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

h3 {
    margin: 10px 0;
    text-align: center;
}

.filter-options {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-options label {
    display: flex;
    align-items: center;
}

.filter-options select {
    margin-left: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.device-filters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
}

li {
    cursor: pointer;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s;
}

li:hover {
    background-color: #e2e6ea;
}

li.selected {
    background-color: #007bff;
    color: white;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.access-reports-container p{
    text-align: center;
    color: red;
}

.access-reports-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

th {
    background-color: #007bff;
    color: white;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

/* Responsive stiller */
@media (max-width: 768px) {
    .access-reports-container {
        padding: 10px;
    }

    th, td {
        padding: 10px;
    }
}
