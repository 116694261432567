* {
    padding: 0;
    margin: 0;
    font-family: 'Agenor Neue', sans-serif;
}

.container {
    min-height: 97vh;
}

.banner {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.bannerImage {
    border-radius: 0;
    width: 100%;
    height: 350px;
    object-fit: fill;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
}

.bannerText {
    position: absolute;
    font-weight: bold;
    font-size: 55px;
    color: #fff;
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 10px;
}

.title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 50px;
    color: #9fcb52;
}


.btnLogin {
    display: block;
    margin: 0 auto 30px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnLogin:hover {
    background-color: #0056b3;
}

.btnBack {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnBack:hover {
    background-color: #218838;
}

.categoryTitle {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 15px;
    color: #9fcb52;
}

.categoryList {
    padding: 0;
    margin: 0;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
}

.categoryItem {
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 400px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.categoryItem:hover {
    transform: scale(1.05);
    background-color: #23222b;
}

.categoryName {
    position: absolute;
    left: 5px;
    bottom: 5px;
    margin-right: 5px !important;
    text-align: start;
    font-size: 24px;
    color: #9fcb52;
    background-color: rgba(35, 34, 43, 1);
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 0;
}

.categoryImage {
    border-radius: 15px;
    width: 100%;
    height: 200px;
    margin-bottom: 0px;
    object-fit: cover;
}

.noCategories {
    text-align: center;
    color: #999;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .categoryItem {
        justify-content: space-between;
        align-items: center;
        width: 85%;
        text-align: center;
        align-items: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    }

    .categoryList {
        gap: 5px;
    }

    .title {
        margin-bottom: 10px;
    }

    .bannerImage {
        border-radius: 0;
        width: 100%;
        height: 300px;
        object-fit: fill;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
    }

}