.footer {
    background-color: #0d1724;
    text-align: center;
    padding: 10px 0px;
    position: absolute;
    margin-bottom: 0px;
    width: 100%;
    box-shadow: 0 -1px 5px rgba(0,0,0,0.1);
  }
  
  .footer a {
    color: #f3f3f3;
    font-weight: bolder;
    text-decoration: none;
  }

@media (max-width: 760px){
  .footer{
    padding: 5px 0px;
    width: 100;
    align-items: center;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    box-shadow: none;
  }
  .footer a{
    font-weight: normal;
    margin-left: 30px;
  }
}